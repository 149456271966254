<template>
    <div>
        <div class="modal-body">
            <div class="row gutters">
                <div class="form-group col-6">
                    <label for="name_document">Nome do Documento</label>
                    <input type="text" id="name_document" class="form-control" placeholder="Nome do Documento"
                           v-model="filter.name_document">
                </div>
                <div class="form-group col-6">
                    <label for="signer">Signatário</label>
                    <input type="text" id="signer" class="form-control" placeholder="Signatário"
                           v-model="filter.signer">
                </div>
                <div class="form-group" :class="isAwaiting ? 'col-4' :'col-6'">
                    <label for="date_expiration">Data de Expiração inicial</label>
                    <input type="date" id="date_expiration" class="form-control" v-model="filter.expiration_date">
                </div>
                <div class="form-group" :class="isAwaiting ? 'col-4' :'col-6'">
                    <label for="date_expiration">Data de Expiração final</label>
                    <input type="date" id="date_expiration_end" class="form-control" v-model="filter.expiration_date_end">
                </div>
                <div class="form-group col-4" v-if="isAwaiting">
                    <label>Pendentes Por:</label>
                    <select2 :settings="{width: '100%'}" :options="[
                            {id:'seller,witness', text: 'Administração'},
                            {id:'buyer', text: 'Compradores'},
                            {id:'broker', text: 'Corretores'},
                            {id:'seller', text: 'Vendedores'},
                            {id:'witness', text: 'Testemunhas'},
                            ]"
                             v-model="filter.pendingBy"
                             placeholder="Selecione"
                    ></select2>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button class="btn-secondary btn" @click="clearFilter()">Limpar</button>
            <button class="btn-primary btn" @click="$emit('filtering', filter)">Filtrar</button>
        </div>
    </div>
</template>

<script>
import Select2 from 'vue3-select2-component';

export default {
    name: "filterModalAssign",
    props:{
        isAwaiting:{
            default: false,
            type: Boolean,
        }
    },
    components: {
        Select2,
    },
    data() {
        return {
            filter: {},
        }
    },
    methods: {
        clearFilter() {
            this.filter = {};
            this.$emit('clearFilter')
        }
    }
}
</script>

<style scoped>

</style>